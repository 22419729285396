import {
  Booking,
  BookingRequest,
  User,
  Vehicle,
} from '../../@generated/schemas';
import { Box, IconButton } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDataProvider, useUpdate } from 'react-admin';

import { BookingSchedulerDialog } from './schedulerBookingDialog';
import EditIcon from '@mui/icons-material/Edit';
import { LoadingElement } from '../../App';
import Paper from '@mui/material/Paper';
import { ParsedDatesRange } from '../../libs/scheduler/utils/getDatesRange';
import { RequestBookingSchedulerDialog } from './schedulerRequestBookingDialog';
import { Scheduler } from '../../libs/scheduler';
import { SchedulerData } from '../../libs/scheduler';
import { SchedulerProjectData } from '../../libs/scheduler/types/global';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import styled from 'styled-components';

export const StyledSchedulerFrame = styled.div`
  position: relative;
  height: calc(80vh - 100px);
  width: (100vw - 200px);
`;

export const StyledTableContainer = styled(TableContainer)`
  position: relative;
  height: calc(25vh);
  width: (100vw - 300px);
`;

export const LoadingElementWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
`;

export const ModalBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  padding: 6px 12px;
  box-shadow: 24px;
`;

export const BookingScheduler = () => {
  const [values, setValues] = useState({
    startDate: dayjs().toDate(),
    loading: true,
  });
  const dataProvider = useDataProvider();
  const [update] = useUpdate();
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const [bookingRequests, setBookingRequests] = useState<BookingRequest[]>([]);
  const [updatingBooking, setUpdatingBooking] = useState(false);
  const [selectedBooking, setSelectedBooking] =
    useState<SchedulerProjectData>(null);
  const [selectedBookingRequest, setSelectedBookingRequest] =
    useState<Partial<BookingRequest>>(null);
  const [schedulerData, setSchedulerData] = useState<SchedulerData>([]);

  const [range, setRange] = useState<ParsedDatesRange>({
    startDate: dayjs().startOf('day').toDate(),
    endDate: dayjs().endOf('day').toDate(),
  });

  const refetchBookings = async (
    showLoading = true,
    range: ParsedDatesRange,
  ) => {
    showLoading && setUpdatingBooking(true);
    const { data: _bookings } = await dataProvider.getList<Booking>('Booking', {
      pagination: {
        page: 1,
        perPage: 100000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {
        startDate: {
          gte: range.startDate,
          lte: range.endDate,
        },
        cancelDate: {
          equals: null,
        },
      },
    });

    console.log('aaaa', {
      startDate: {
        gte: range.startDate,
        lte: range.endDate,
      },
      cancelDate: {
        equals: null,
      },
    });

    const { data: _bookingRequests } =
      await dataProvider.getList<BookingRequest>('BookingRequest', {
        pagination: {
          page: 1,
          perPage: 100000,
        },
        sort: {
          field: 'id',
          order: 'ASC',
        },
        filter: {},
      });

    setBookings(_bookings);
    setBookingRequests(_bookingRequests);

    showLoading && setUpdatingBooking(false);
  };

  const refetchAll = async (showLoading = true) => {
    showLoading && setUpdatingBooking(true);
    const { data: _vehicles } = await dataProvider.getList<Vehicle>('Vehicle', {
      pagination: {
        page: 1,
        perPage: 10000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {
        deletedAt: {
          equals: null,
        },
      },
    });

    const { data: _users } = await dataProvider.getList<User>('User', {
      pagination: {
        page: 1,
        perPage: 10000,
      },
      sort: {
        field: 'id',
        order: 'ASC',
      },
      filter: {
        deletedAt: {
          equals: null,
        },
      },
    });

    const userOptions = (_users || []).map((user) => ({
      id: user.id,
      label:
        user.firstName && user.lastName
          ? `${user.firstName} ${user.lastName} (${user.email})`
          : user.email,
    }));

    setUsers(userOptions);
    setVehicles(_vehicles);

    showLoading && setUpdatingBooking(false);
  };

  useEffect(() => {
    refetchAll(false);
  }, []);

  const handleRangeChange = useCallback((range: ParsedDatesRange) => {
    console.log('range.startDate - ', range.startDate);
    setRange(range);
    refetchBookings(true, range);
  }, []);

  useEffect(() => {
    const _vehicles = {};
    for (const vehicle of vehicles || []) {
      if (!_vehicles[vehicle.id]) {
        _vehicles[vehicle.id] = {
          id: vehicle.id,
          label: {
            title: vehicle.plateNumber,
            subtitle: `${vehicle.brandType.name} ${vehicle.modelType.name}`,
          },
          collapsed: true,
          data: [],
        };
      }
    }

    for (const booking of bookings || []) {
      if (_vehicles[booking.vehicleId]) {
        _vehicles[booking.vehicleId].data.push({
          id: booking.id,
          vehicleId: booking.vehicleId,
          userId: booking.userId,
          startDate: booking.startDate,
          bookingStatus: booking.bookingStatus,
          adminBookingComment: booking.adminBookingComment,
          endDate: booking.endDate,
          title: `${booking.user.lastName} ${booking.user.firstName}`,
          description: booking.bookingHash,
          subtitle: '',
        });
      }
    }

    setSchedulerData(Object.values(_vehicles) || []);
  }, [bookings, vehicles, range.endDate, range.startDate]);

  const handleFilterData = () => console.log(`Filters button was clicked.`);

  const handleTileClick = (data: SchedulerProjectData) =>
    setSelectedBooking(data);

  const onVehicleClick = (vehicleId: string) => {
    setSchedulerData((prevData) => {
      return prevData.map((vehicle) => {
        if (vehicle.id === vehicleId) {
          return {
            ...vehicle,
            collapsed: !vehicle.collapsed,
          };
        }

        return vehicle;
      });
    });
  };

  const onItemDrop = (from, to) => {
    console.log('onItemDrop - ', from, to);
  };
  const handleItemResize = async (bookingId: string, newEndDate: Date) => {
    console.log('handleItemResize - ', bookingId, newEndDate);
    setUpdatingBooking(true);

    const booking = bookings.find((booking) => booking.id === bookingId);

    if (booking) {
      await update('Booking', {
        id: booking.id as string,
        data: {
          startDate: dayjs(booking.startDate).format(),
          endDate: dayjs(newEndDate).format(),
          user: booking.userId,
          vehicle: booking.vehicleId,
          adminBookingComment: booking.adminBookingComment,
        },
      });

      setUpdatingBooking(false);

      await refetchBookings(true, range);
    }
  };
  const handleCreateBooking = async (startDate: Date, vehicleId: string) => {
    setUpdatingBooking(true);

    const newBooking: SchedulerProjectData = {
      id: 'NEW',
      vehicleId,
      userId: null,
      startDate,
      adminBookingComment: '',
      endDate: dayjs(startDate).add(1, 'hours').toDate(),
      title: 'New booking',
      occupancy: 0,
    };

    setSelectedBooking(newBooking);

    setUpdatingBooking(false);
  };

  const handleSelectRow = (bookingRequest: BookingRequest) => {
    setUpdatingBooking(true);

    const newBooking: SchedulerProjectData = {
      id: 'NEW',
      vehicleId: null,
      bookingRequestId: bookingRequest.id,
      userId: bookingRequest.userId,
      startDate: bookingRequest.startDate,
      endDate: bookingRequest.endDate,
      adminBookingComment: '',
      title: 'New booking',
      occupancy: 0,
    };

    setSelectedBooking(newBooking);

    setUpdatingBooking(false);
  };

  const handleNewRequest = () => {
    setUpdatingBooking(true);

    const newRequestBooking: Partial<BookingRequest> = {
      id: 'NEW',
      userId: null,
      startDate: dayjs().toDate(),
      endDate: dayjs().add(1, 'hours').toDate(),
    };

    setSelectedBookingRequest(newRequestBooking);

    setUpdatingBooking(false);
  };

  const handleOpenBookingRequest = (event: any, data: BookingRequest) => {
    event.stopPropagation();

    setSelectedBookingRequest(data);
  }

  return (
    <div>
      <StyledSchedulerFrame>
        {updatingBooking ? (
          <LoadingElementWrapper>
            <LoadingElement />
          </LoadingElementWrapper>
        ) : null}
        <Scheduler
          // startDate={values.startDate}
          onItemDrop={onItemDrop}
          onClickNewRequest={handleNewRequest}
          onRangeChange={handleRangeChange}
          data={schedulerData}
          // isLoading={updatingBooking}
          onVehicleClick={onVehicleClick}
          onTileClick={handleTileClick}
          onFilterData={handleFilterData}
          onItemResize={handleItemResize}
          onItemCreate={handleCreateBooking}
          config={{
            zoom: 2,
            lang: 'en',
            showThemeToggle: true,
            filterButtonState: null,
          }}
        />
      </StyledSchedulerFrame>

      <BookingSchedulerDialog
        bookingId={selectedBooking?.id}
        bookingRequestId={selectedBooking?.bookingRequestId}
        loading={updatingBooking}
        vehicles={vehicles}
        users={users}
        setLoading={setUpdatingBooking}
        userId={selectedBooking?.userId}
        adminBookingComment={selectedBooking?.adminBookingComment}
        startDate={selectedBooking?.startDate}
        endDate={selectedBooking?.endDate}
        bookingStatus={selectedBooking?.bookingStatus}
        vehicleId={selectedBooking?.vehicleId}
        description={selectedBooking?.description}
        refetchBookings={refetchBookings}
        resetSelectedBooking={() => setSelectedBooking(null)}
        setSelectedBookingParam={(param, value) =>
          setSelectedBooking({
            ...selectedBooking,
            [param]: value,
          })
        }
      />

      <RequestBookingSchedulerDialog
        id={selectedBookingRequest?.id}
        loading={updatingBooking}
        users={users}
        setLoading={setUpdatingBooking}
        userId={selectedBookingRequest?.userId}
        startDate={selectedBookingRequest?.startDate}
        endDate={selectedBookingRequest?.endDate}
        refetchBookings={refetchBookings}
        resetSelectedRequestBooking={() => setSelectedBookingRequest(null)}
        setSelectedRequestBookingParam={(param, value) =>
          setSelectedBookingRequest({
            ...selectedBookingRequest,
            [param]: value,
          })
        }
      />

      <StyledTableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Start date</TableCell>
              <TableCell>End date</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookingRequests.map((row) => (
              <TableRow
                key={row.id}
                style={{ cursor: 'pointer' }}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell
                  onClick={() => handleSelectRow(row)}
                  component="th"
                  scope="row">
                  {row.user.firstName} {row.user.lastName} ({row.user.email})
                </TableCell>
                <TableCell onClick={() => handleSelectRow(row)}>
                  {dayjs(row.startDate).format('DD.MM. YYYY HH:mm')}
                </TableCell>
                <TableCell onClick={() => handleSelectRow(row)}>
                  {dayjs(row.endDate).format('DD.MM. YYYY HH:mm')}
                </TableCell>
                <TableCell align="right" onClick={() => handleSelectRow(row)}>
                  <IconButton
                    size="small"
                    onClick={(event: any) => handleOpenBookingRequest(event, row)}>
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </div>
  );
};
