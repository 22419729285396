import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  TextField as MuiTextField,
} from '@mui/material';
import { useDelete, useNotify, useUpdate } from 'react-admin';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { BookingStatus } from '../../@generated/schemas';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/lab';
import { Link } from 'react-router-dom';
import { LoadingElement } from '../../App';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';
import enLocale from 'date-fns/locale/en-GB';
import styled from 'styled-components';
import { useCreateNewBookingMutation } from '../../@generated/hooks';

export const LoadingElementWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const BookingSchedulerDialog = ({
  bookingId,
  startDate,
  endDate,
  bookingRequestId,
  description,
  vehicles,
  users,
  bookingStatus,
  vehicleId,
  adminBookingComment,
  userId,
  loading,
  setLoading,
  refetchBookings,
  setSelectedBookingParam,
  resetSelectedBooking,
}) => {
  const [createNewBooking] = useCreateNewBookingMutation();
  const [update] = useUpdate();
  const [deleteBookingRequest] = useDelete();
  const notify = useNotify();

  const handleCloseDetail = () => {
    if (!loading) {
      resetSelectedBooking(null);
    }
  };

  const handleSaveBooking = async () => {
    setLoading(true);

    if (bookingId && bookingId !== 'NEW') {
      await update(
        'Booking',
        {
          id: bookingId as string,
          data: {
            startDate: dayjs(startDate).format(),
            endDate: dayjs(endDate).format(),
            user: userId,
            vehicle: vehicleId,
            adminBookingComment,
          },
        },
        {
          onSuccess: async () => {
            setLoading(false);

            notify('Booking was successfully updated', { type: 'success' });
            await refetchBookings(true, {
              startDate,
              endDate,
            });
            handleCloseDetail();
          },
        },
      );
    } else {
      try {
        await createNewBooking({
          variables: {
            startDate: dayjs(startDate).format(),
            endDate: dayjs(endDate).format(),
            userId: userId,
            vehicleId,
            bookingComment: adminBookingComment,
          },
        });

        if (bookingRequestId) {
          await deleteBookingRequest('BookingRequest', {
            id: bookingRequestId,
          });
        }

        setLoading(false);

        notify('Booking was successfully created', { type: 'success' });
        await refetchBookings(true, {
          startDate,
          endDate,
        });
        handleCloseDetail();
      } catch (error) {
        setLoading(false);
        notify(error.message, { type: 'error' });
      }
    }
  };

  const handleCancelBooking = async () => {
    setLoading(true);

    await update(
      'Booking',
      {
        id: bookingId as string,
        data: {
          cancelDate: dayjs().format(),
          cancellationFees: 0,
        },
      },
      {
        onSuccess: async () => {
          setLoading(false);

          notify('Booking was successfully cancelled', { type: 'success' });
          await refetchBookings(true, {
            startDate,
            endDate,
          });
          handleCloseDetail();
        },
      },
    );
  };

  return (
    <Dialog
      open={!!bookingId}
      onClose={handleCloseDetail}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>
        <div>Booking: {description}</div>
        <div>
          Status:{' '}
          {bookingId && bookingId !== 'NEW' ? bookingStatus : 'Creating'}
        </div>
        <IconButton
          style={{ position: 'absolute', right: 18, top: 14 }}
          onClick={handleCloseDetail}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="modal-modal-title">
        {loading ? (
          <LoadingElementWrapper>
            <LoadingElement />
          </LoadingElementWrapper>
        ) : null}
        <DialogContentText style={{ paddingTop: 6 }}></DialogContentText>
        <LocalizationProvider locale={enLocale} dateAdapter={AdapterDayjs}>
          <FormRow style={{ marginBottom: 20 }}>
            <DateTimePicker
              renderInput={(props) => (
                <MuiTextField
                  {...props}
                  variant="outlined"
                  size="small"
                  error={dayjs(startDate).isAfter(endDate)}
                  style={{ flex: 1, marginRight: 20 }}
                />
              )}
              inputFormat="DD.MM.YYYY HH:mm"
              ampm={false}
              label="Start date time"
              value={startDate}
              onChange={(newValue) => {
                setSelectedBookingParam('startDate', newValue);
              }}
            />
            <DateTimePicker
              renderInput={(props) => (
                <MuiTextField
                  {...props}
                  variant="outlined"
                  size="small"
                  error={dayjs(endDate).isBefore(startDate)}
                  style={{ flex: 1 }}
                />
              )}
              inputFormat="DD.MM.YYYY HH:mm"
              ampm={false}
              label="End date time"
              value={endDate}
              onChange={(newValue) => {
                setSelectedBookingParam('endDate', newValue);
              }}
            />
          </FormRow>
        </LocalizationProvider>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <Autocomplete
            value={users.find((user) => user.id === userId)}
            size="small"
            placeholder=""
            getOptionLabel={(option) => option.label}
            options={users}
            onChange={(event: any, value: any) => {
              console.log('onChange', value);
              setSelectedBookingParam('userId', value.id);
            }}
            renderInput={(params) => (
              <MuiTextField {...params} placeholder="" label="User" />
            )}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <Autocomplete
            value={vehicles.find((vehicle) => vehicle.id === vehicleId)}
            size="small"
            placeholder=""
            getOptionLabel={(option) =>
              `${option.plateNumber} (${option.brandType.name} ${option.modelType.name})`
            }
            options={vehicles}
            onChange={(event: any, value: any) => {
              setSelectedBookingParam('vehicleId', value.id);
            }}
            renderInput={(params) => (
              <MuiTextField {...params} placeholder="" label="Vehicle" />
            )}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <MuiTextField
            value={adminBookingComment}
            size="small"
            onChange={(event) =>
              setSelectedBookingParam('adminBookingComment', event.target.value)
            }
            placeholder=""
            label="Comment"
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <Button onClick={handleCloseDetail}>Close</Button>
        {bookingId && bookingId !== 'NEW' ? (
          <Link target="_blank" to={`/Booking/${bookingId}/show`}>
            <Button>Booking detail</Button>
          </Link>
        ) : null}

        {[
          undefined,
          BookingStatus.Late,
          BookingStatus.Confirmed,
          BookingStatus.NoShow,
          BookingStatus.OwnBooking,
          BookingStatus.Pending,
          BookingStatus.Running,
          BookingStatus.WaitingForUser,
        ].includes(bookingStatus) ? (
          <Button
            variant="contained"
            onClick={handleSaveBooking}
            disabled={dayjs(endDate).isBefore(startDate)}
            style={{ marginLeft: 'auto' }}>
            Save
          </Button>
        ) : null}
        {![
          BookingStatus.Aborted,
          BookingStatus.Cancelled,
          BookingStatus.PaymentFailed,
          BookingStatus.Revoked,
        ].includes(bookingStatus) &&
        bookingId &&
        bookingId !== 'NEW' ? (
          <Button
            variant="contained"
            color="error"
            onClick={handleCancelBooking}>
            Cancel
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
